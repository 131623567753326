.canvas{
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    z-index: 0;
}

/* 
*{
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
} */

.canvas-container{
    z-index: 1;
}

.black-screen{
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    position: fixed;
    z-index: 1;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
}

.full-screen {
    position: fixed;
    background-color: grey;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }


.ui-element{
    width: 10px;
    height: 10px;
    border: 2px solid rgb(49, 49, 49);
    border-radius: 60%;
    position: fixed;
    left: 50%;
    top: 49%;
    z-index: 2;
}
.pause-screen{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2;
}

.center-ui{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
}

.center-ui-startbutton-only{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 430px;
}

.intro-box{
  font-family: 'Ubuntu';
  font-weight: normal;
  opacity: 75%;
  color: white;
  background-color: rgb(65, 65, 65);
  border: 3px solid rgb(41, 41, 41);
  border-radius: 4px;
  width: 340px;
  font-size: 18px;
  height: 180px;
  left: 41%;
  top: 22%;
  z-index: 1;
  margin-bottom: 50px;
}

.instruction{
    font-family: 'Ubuntu';
    font-weight: normal;
    opacity: 75%;
    color: white;
    background-color: rgb(65, 65, 65);
    border: 3px solid rgb(41, 41, 41);
    border-radius: 4px;
    width: 265px;
    font-size: 20px;
    height: 150px;
    position: fixed;
    left: 2%;
    top: 3%;
    z-index: 1;
}
.start-button{
    font-family: 'Ubuntu';
    color: rgb(255, 255, 255);
    background-color: rgb(66, 66, 66);
    border: 3px solid rgb(41, 41, 41);
    border-radius: 15px;
    width: 180px;
    font-size: 20px;
    height: 50px;
    left: 45.7%;
    top: 49%;
    z-index: 1;
}
.start-button:hover{
    background-color: rgb(133, 133, 133);
}

.floatbox{
    width: 30px;
    height: 20px;
    background-color: black;
    border: 0.5px solid white;
    color: white;
    font-size: 5px;
    opacity: 45%;
    z-index: -1;
    position: fixed;
    animation: fadeInHalf 2s ease-in-out forwards;

}

@keyframes fadeIn {
    from {
      opacity: 0; 
    }
    to {
      opacity: 1;
    }
  }


  @keyframes fadeInHalf {
    from {
      opacity: 0%; 
    }
    to {
      opacity: 45%;
    }
  }
  
nav {
  padding: 10px;
  height: 10vh;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-width: 2px;
  border-style: solid;
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
  /*opacity: 0; 
  animation: fadeIn 1s linear infinite;*/
  border-image: linear-gradient(to right, #868788ab, #ffffff) 30;
}

.title {
  position: absolute;
  left: 20px;
  font-size: 28px;
  font-weight: bold;
  font-family: "Rounded";
  color: black;
  margin-top: -30px;
}

ul {
  list-style: none;
  display: flex;
  justify-content: flex-start;
}
.navbar-items {
  display: flex;
  font-family: "Ubuntu";
  margin-right: 20px;
}
.navbar-items h2 {
  margin: 0 auto;
  font-size: 20px;
}
.navbar-items a {
  display: inline-block;
  text-decoration: none;
  color: black;
  margin-right: 30px;
  cursor: pointer;
}

li {
  margin-left: 50px;
  cursor: pointer;
  font-family: "Ubuntu";
  font-size: 17px;
  color: black;
}
.customlink {
  text-decoration: none;
  color: white;
}

.gradient-border {
  --border-width: 3px;

  font-family: "Rounded";
  text-shadow: 0 3px 6px #cacaca;
  position: absolute;
  left: 15px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 50px;
  font-size: 30px;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  background: #ffffff;
  border-radius: var(--border-width);

  &::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
      60deg,
      hsl(0, 0%, 24%),
      hsl(0, 0%, 34%),
      hsl(0, 0%, 44%),
      hsl(0, 0%, 57%),
      hsl(0, 0%, 81%),
      hsl(0, 0%, 59%),
      hsl(0, 0%, 51%),
      hsl(0, 0%, 39%)
    );
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradient 4s alternate infinite;
  }
}

@media screen and (max-width: 1000px) {
  .gradient-border {
    display: none !important;
  }
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

@media only screen and (max-width: 600px) {
  .navbar-items {
    margin-right: 0px; /* Or adjust as needed */
  }
}

.navbar-items a {
  display: block;
  position: relative;
  padding: 0.2em 0;
}

.navbar-items a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.2em;
  background-color: rgb(0, 0, 0);
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.navbar-items a:hover::after,
.navbar-items a:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
}

.aboutme-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  font-family: "Ubuntu";
  min-width: 0;
  margin-top: 250px;
}
.aboutme-content {
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-align: left;
  display: flex;
  max-width: 100%;
}
.aboutme-textcontainer {
  font-family: "Ubuntu";
  font-size: 1em;
  max-width: 50%;
}

.aboutme-text {
  color: grey;
}
.aboutme-picture {
  width: 380px;
  height: 300px;
}
.aboutme-stack-container {
  display: flex;
  flex-direction: column;
  font-family: "Ubuntu";
  margin-top: 150px;
  justify-content: center;
  align-items: center;
}
.aboutme-stack-text {
  text-align: center;
  border-style: solid;
  border-color: grey;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-width: 2px;
  width: 30%;
}
.aboutme-stack-img-container {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.aboutme-stack-img {
  margin-right: 7px;
  width: 40px;
  height: 40px;
}

@media only screen and (max-width: 710px) {
  .aboutme-content {
    flex-direction: column;
  }
}

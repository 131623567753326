*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: white;
}

.description-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*margin-left: -80px;*/
  font-family: "Ubuntu";
  margin-top: 230px;
}
.description-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.description-text {
  flex: 1;
  max-width: 50%;
  padding-right: 20px;
  margin: 40px;
}
.description-text a {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
  margin-top: 5px;
}

.about-text {
  color: grey;
  font-size: 16px;
}

.profilepicture {
  border: 4px solid rgb(224, 224, 224);
  border-radius: 50%;
  height: auto;
  width: 220px;
  height: 220px;
  max-width: 100%;
}

.linkedinpicture {
  width: 25px;
  height: 25px;
  margin-right: 15px;
  margin-top: 5px;
}
@media screen and (max-width: 600px) {
  .description-container {
    margin-top: 50px;
  }
}

@font-face {
  font-family: "Rounded";
  src: url("../fonts/Rounded_Elegance.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/Ubuntu-Medium.ttf") format("truetype"),
    url("../fonts/Ubuntu-BoldItalic.ttf") format("truetype"),
    url("../fonts/Ubuntu-Italic.ttf") format("truetype"),
    url("../fonts/Ubuntu-Light.ttf") format("truetype"),
    url("../fonts/Ubuntu-Medium.ttf") format("truetype"),
    url("../fonts/Ubuntu-Regular.ttf") format("truetype");
  font-display: swap;
}
